import React from 'react';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';

import { useApi } from '@backstage/core-plugin-api';
import moment from 'moment';
import { opsgenieApiRef } from '../../api';
import { useAsync } from 'react-use';

export const Analytics = () => {
  const opsgenieApi = useApi(opsgenieApiRef);

  const [analyticsMetrics, setAnalyticsMetrics] = React.useState({
    alertsCreated: 0,
    mtta: 0, // Mean Time to Acknowledge in minutes
    mttr: 0, // Mean Time to Resolve in minutes
  });

  // Fetch and calculate analytics metrics
  const {} = useAsync(async () => {
    const alertsResponse = await opsgenieApi.getAlerts();
    const alerts = alertsResponse.filter(
      alert =>
        alert.acknowledged &&
        moment(alert.createdAt).isAfter(moment().subtract(1, 'month')),
    );

    let totalAckTime = 0;
    let totalCloseTime = 0;
    let acknowledgedAlertsCount = 0;
    let closedAlertsCount = 0;

    alerts.forEach(alert => {
      if (alert.acknowledged) {
        totalAckTime += alert.report.ackTime;
        acknowledgedAlertsCount++;
      }
      if (alert.status === 'closed') {
        totalCloseTime += alert.report.closeTime;
        closedAlertsCount++;
      }
    });

    const mtta =
      acknowledgedAlertsCount > 0
        ? totalAckTime / acknowledgedAlertsCount / 1000 / 60
        : 0; // Convert ms to minutes
    const mttr =
      closedAlertsCount > 0
        ? totalCloseTime / closedAlertsCount / 1000 / 60
        : 0; // Convert ms to minutes

    setAnalyticsMetrics({
      alertsCreated: alerts.length,
      mtta,
      mttr,
    });

    return alerts; // This is not used but required by useAsync hook
  }, []);

  // Convert minutes to hours, minutes, and seconds
  const mttaHours = Math.floor(analyticsMetrics.mtta / 60);
  const mttaMinutes = Math.floor(analyticsMetrics.mtta % 60);
  const mttaSeconds = Math.round(
    (analyticsMetrics.mtta - Math.floor(analyticsMetrics.mtta)) * 60,
  );

  const mttrHours = Math.floor(analyticsMetrics.mttr / 60);
  const mttrMinutes = Math.floor(analyticsMetrics.mttr % 60);
  const mttrSeconds = Math.round(
    (analyticsMetrics.mttr - Math.floor(analyticsMetrics.mttr)) * 60,
  );

  return (
    <Grid container spacing={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h2>Quick Insight into This Month</h2>
        </Grid>
        <Grid item md={4} xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="h2">
                Alerts Created
              </Typography>
              <Typography variant="body2" component="p">
                {analyticsMetrics.alertsCreated}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4} xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="h2">
                Mean Time to Acknowledge
              </Typography>
              <Typography variant="body2" component="p">
                {`${
                  mttaHours > 0
                    ? `${mttaHours} hour${mttaHours !== 1 ? 's' : ''}, `
                    : ''
                }${mttaMinutes} minute${
                  mttaMinutes !== 1 ? 's' : ''
                } and ${mttaSeconds} second${mttaSeconds !== 1 ? 's' : ''}`}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4} xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="h2">
                Mean Time to Resolve
              </Typography>
              <Typography variant="body2" component="p">
                {`${
                  mttrHours > 0
                    ? `${mttrHours} hour${mttrHours !== 1 ? 's' : ''}, `
                    : ''
                }${mttrMinutes} minute${
                  mttrMinutes !== 1 ? 's' : ''
                } and ${mttrSeconds} second${mttrSeconds !== 1 ? 's' : ''}`}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};
