import { BackstageTheme, lightTheme } from '@backstage/theme';
import { BackstageOverrides } from '@backstage/core-components';

const sasBlue = '#000099';
const sasFont = 'scandinavian, "Open Sans", arial, sans-serif';

const createCustomThemeOverrides = (): BackstageOverrides => {
  return {
    BackstageHeader: {
      header: {
        backgroundImage: 'unset',
        backgroundColor: sasBlue,
        width: 'auto',
        boxShadow: 'none',
      },
    },
    MuiButton: {
      containedPrimary: {
        'align-items': 'center',
        'font-family': sasFont,
        'font-weight': 800,
        backgroundColor: '#006FE6',
        '&:hover': {
          backgroundColor: '#0159ce',
        },
      },
    },
    MuiInputBase: {
      root: {
        color: sasBlue,
      },
    },
    MuiSelect: {
      root: {
        'font-family': sasFont,
      },
    },
    MuiTypography: {
      colorPrimary: {
        color: sasBlue,
      },
      h1: {
        'font-family': sasFont,
        color: sasBlue,
      },
      h2: {
        'font-family': sasFont,
        color: sasBlue,
      },
      h3: {
        'font-family': sasFont,
        color: sasBlue,
      },
      h4: {
        'font-family': sasFont,
        color: sasBlue,
      },
      h5: {
        'font-family': sasFont,
        color: sasBlue,
      },
      subtitle1: {
        'font-family': sasFont,
        color: sasBlue,
      },
    },
    MuiCardMedia: {
      root: {
        'background-image': 'unset',
      },
    },
    BackstageSidebarItem: {
      buttonItem: {
        'margin-top': '15px',
      },
    },
  };
};

export const sasTheme: BackstageTheme = {
  ...lightTheme,
  overrides: {
    // These are the overrides that Backstage applies to `material-ui` components
    ...lightTheme.overrides,
    // These are your custom overrides, either to `material-ui` or Backstage components.
    ...createCustomThemeOverrides(),
  },
};
