import { TechRadarApi } from '@backstage-community/plugin-tech-radar';
import {
  RadarRing,
  RadarQuadrant,
  RadarEntry,
  TechRadarLoaderResponse,
} from '@backstage-community/plugin-tech-radar-common';

let rings = new Array<RadarRing>();
let quadrants = new Array<RadarQuadrant>();
let entries = new Array<RadarEntry>();

export class CustomTechRadarAPI implements TechRadarApi {
  async load(): Promise<TechRadarLoaderResponse> {
    /**
     * Payload for the tech-radar is fetched from a publicly exposed blob file in Azure storage
     * When there is backstage maturity within the team:
     *  1) this logic should be updated and the payload should be fetched from an authenticated source
     *  2) environment should be fetched from the config file
     */
    let azStorageName = 'cdxbackstagetrsa';

    if (
      window.location.hostname === 'devportal-test.flysas.tech' ||
      window.location.hostname === 'localhost'
    ) {
      azStorageName = 'cdxtestbackstagetrsa';
    }

    let entriesPayload = await fetch(
      `https://${azStorageName}.blob.core.windows.net/tech-radar/sas-tech-radar-entries.json`,
      { cache: 'no-store' },
    ).then(res => res.json());
    const quadrantsPayload = await fetch(
      `https://${azStorageName}.blob.core.windows.net/tech-radar/quadrants.json`,
      { cache: 'no-store' },
    ).then(res => res.json());
    const ringsPayload = await fetch(
      `https://${azStorageName}.blob.core.windows.net/tech-radar/rings.json`,
      { cache: 'no-store' },
    ).then(res => res.json());

    entriesPayload = {
      entries: entriesPayload.entries.map(
        (entry: {
          [x: string]: any;
          moved: any;
          ring: any;
          url: any;
          title: any;
          quadrant: any;
        }) => ({
          timeline: [
            {
              moved: entry.moved,
              ringId: entry.ring,
              date: new Date(),
            },
          ],
          url: entry.url,
          description: entry.description,
          key: entry.title,
          id: entry.title,
          title: entry.title,
          quadrant: entry.quadrant,
        }),
      ),
    };

    rings = ringsPayload.rings;
    quadrants = quadrantsPayload.quadrants;
    entries = entriesPayload.entries;

    const globalTechRadarPayload: TechRadarLoaderResponse = {
      entries,
      quadrants,
      rings,
    };

    return globalTechRadarPayload;
  }
}
