import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: '85px',
    background: '#000099',
    position: 'absolute',
    top: '0px',
    left: '0px',
  },
  path: {
    fill: '#FFFFFF',
  },
});

const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-30 -15 350 130"
    >
      <path
        className={classes.path}
        fill="#2B3087"
        d="M127.376,19.077c-6.966,0-16.508,0.005-17.797,0.005
        c-0.897,0-1.267,0.518-1.267,0.518L59.276,80.422c-0.286,0.351-0.225,0.712,0.269,0.712c1.125,0,5.653,0,7.259-0.003
        c0.728,0,0.8-0.195,0.929-0.385l10.438-13.382c0,0,0.162-0.244,0.381-0.244c0.17,0,17.521-0.002,17.521-0.002
        s0.269-0.018,0.216,0.246c-0.055,0.263-2.557,13.121-2.557,13.121c-0.032,0.174,0.017,0.646,0.626,0.646
        c2.289,0,18.766-0.004,21.173-0.004c0.306,0,0.413-0.102,0.472-0.397l11.929-61.014C127.93,19.716,128.144,19.077,127.376,19.077
        L127.376,19.077L127.376,19.077L127.376,19.077z M97.378,60.79h-13.78c0,0-0.088,0.02-0.147-0.064
        C83.396,60.645,83.5,60.52,83.5,60.52l18.318-23.493c0,0,0.138-0.183,0.283-0.183c0.145,0,0.094,0.203,0.094,0.203l-4.593,23.557
        c0,0-0.014,0.099-0.059,0.143C97.5,60.79,97.378,60.79,97.378,60.79L97.378,60.79L97.378,60.79z"
      />
      <path
        className={classes.path}
        fill="#2B3087"
        d="M11.914,73.378c-0.174,0.726,0.221,1.341,1.537,2.427
        c2.48,2.045,9.841,6.483,21.52,6.48c21.027-0.004,28.652-13.596,25.986-25.114c-0.714-3.081-4.653-19.019-5.223-21.263
        c-1.657-6.524,1.514-11.224,7.74-11.229c5.323-0.004,8.646,2.015,11.555,3.839c0.547,0.349,0.949,0.539,1.094-0.101
        c0.086-0.37,1.042-5.33,1.042-5.33s0.282-0.961-0.97-1.615c-1.934-1.008-6.744-3.734-16.887-3.738
        C40.451,17.724,32.425,29.828,35.709,42.95c1.05,4.198,5.255,19.915,5.271,22.943c0.002,1.163,0.615,9.187-9.05,9.187
        c-8.975,0-15.154-6.131-17.24-8.252c-0.344-0.354-0.804-0.159-1.019,0.613C13.56,67.841,11.914,73.378,11.914,73.378L11.914,73.378
        L11.914,73.378z"
      />
      <path
        className={classes.path}
        fill="#2B3087"
        d="M123.427,73.695c-0.173,0.727,0.221,1.344,1.539,2.431
        c2.481,2.042,9.258,6.144,20.939,6.139c21.027-0.003,28.651-13.594,25.982-25.117c-0.709-3.077-4.652-19.014-5.221-21.26
        c-1.653-6.526,1.517-11.225,7.738-11.23c5.325-0.001,8.648,2.017,11.554,3.842c0.551,0.348,0.952,0.534,1.097-0.103
        c0.084-0.371,1.041-5.329,1.041-5.329s0.287-0.958-0.969-1.615c-1.932-1.011-6.748-3.733-16.882-3.737
        c-18.86-0.012-26.886,12.094-23.603,25.215c1.051,4.198,5.257,19.912,5.272,22.946c0.003,1.16,0.618,9.181-9.05,9.181
        c-8.979,0.002-14.901-5.914-17.002-8.016c-0.467-0.463-1.163-0.292-1.258,0.383C124.547,67.829,123.427,73.695,123.427,73.695
        L123.427,73.695L123.427,73.695z"
      />
    </svg>
  );
};

export default LogoFull;
