import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: '120px',
    background: '#f8f8f8',
    position: 'absolute',
    top: '0px',
    left: '0px',
  },
  path: {
    fill: '#000099',
  },
});

const LogoWhiteBG = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2237.7 1200"
    >
      <path
        className={classes.path}
        fill="#000099"
        d="M1373.2,312.7c-64.7,0-153.3,0-165.3,0c-8.3,0-11.8,4.8-11.8,4.8L740.4,882.6c-2.6,3.3-2.1,6.7,2.5,6.7
	c10.4,0,52.5,0,67.5,0c6.7,0,7.4-1.9,8.6-3.6l97-124.3c0,0,1.5-2.3,3.5-2.3c1.6,0,162.8-0.1,162.8-0.1s2.5-0.1,2,2.4
	c-0.5,2.4-23.8,121.9-23.8,121.9c-0.3,1.6,0.2,6,5.8,6c21.2-0.1,174.3-0.1,196.7-0.1c2.8,0,3.8-0.9,4.4-3.7l110.8-566.9
	C1378.4,318.6,1380.3,312.7,1373.2,312.7z M1139.2,479.6l-42.6,218.9c0,0-0.2,0.9-0.6,1.3c-0.4,0.4-1.5,0.4-1.5,0.4l-128,0.1
	c0,0-0.8,0.1-1.4-0.6c-0.5-0.8,0.5-1.9,0.5-1.9l170.2-218.4c0,0,1.3-1.7,2.6-1.7C1139.7,477.7,1139.2,479.6,1139.2,479.6z
	 M514.6,900c-108.5,0-176.9-41.2-199.9-60.2c-12.3-10.1-15.9-15.8-14.3-22.6c0,0,15.3-51.4,16.3-55.1c2-7.2,6.3-9,9.5-5.7
	c19.3,19.7,76.8,76.7,160.2,76.6c89.8,0,84.1-74.5,84.1-85.3c-0.1-28.1-39.2-174.2-49-213.2C491,412.6,565.5,300.1,740.8,300.2
	c94.2,0,138.9,25.4,156.9,34.7c11.7,6.1,9,15,9,15s-8.9,46.1-9.7,49.5c-1.3,5.9-5.1,4.2-10.2,0.9c-27-17-57.9-35.7-107.4-35.7
	c-57.8,0-87.3,43.7-71.9,104.3c5.3,20.9,41.9,168.9,48.5,197.6C780.8,773.7,710,899.9,514.6,900z M1937.4,349.7
	c0,0-8.9,46.1-9.7,49.5c-1.3,5.9-5.1,4.2-10.2,0.9c-27-17-57.9-35.7-107.3-35.7c-57.8,0-87.3,43.7-71.9,104.3
	c5.3,20.9,41.9,168.9,48.5,197.6c24.8,107.1-46.1,233.4-241.4,233.4c-108.5,0-171.5-38-194.6-57c-12.3-10.1-15.9-15.8-14.3-22.6
	c0,0,10.4-54.5,11-58.3c0.9-6.3,7.3-7.9,11.6-3.6c19.6,19.5,74.6,74.5,158,74.5c89.8,0,84.1-74.5,84.1-85.3
	c-0.1-28.1-39.2-174.2-49-213.2c-30.5-121.9,44.1-234.4,219.3-234.3c94.2,0.1,138.9,25.4,156.9,34.7
	C1940.1,340.8,1937.4,349.7,1937.4,349.7z"
      />
    </svg>
  );
};

export default LogoWhiteBG;
