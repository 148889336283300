import React from 'react';
import { Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { TemplateGroupFilter } from '@backstage/plugin-scaffolder-react';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';
import { badgesPlugin } from '@backstage-community/plugin-badges';
import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FeatureFlagged, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { SignInProviderConfig, SignInPage } from '@backstage/core-components';
import {
  AppBar,
  CssBaseline,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { sasTheme } from './theme/sasTheme';
import { darkTheme, lightTheme } from '@backstage/theme';
import { OpsgeniePage } from '@k-phoen/backstage-plugin-opsgenie';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';
import { VisitListener } from '@backstage/plugin-home';
import { AnnouncementsPage } from '@procore-oss/backstage-plugin-announcements';

const AzureProvider: SignInProviderConfig = {
  id: 'azure-auth-provider',
  title: 'Microsoft',
  message: 'Sign in using Azure AD',
  apiRef: microsoftAuthApiRef,
};

const app = createApp({
  apis,
  plugins: [badgesPlugin],
  components: {
    SignInPage: props => (
      <div>
        <AppBar position="static" color="primary">
        <Toolbar style={{ justifyContent: 'center' }}>
            <Typography variant="subtitle1" style={{ color: '#ffffff' }}>
            Having login issues? Contact us on Slack at  <a href="https://sasdigitalhub.slack.com/archives/C02QCKWBJ68">
                ( #talk-to-team-cloud-platform )
              </a> or email <a href="mailto:devex@sas.se">devex@sas.se</a> for support.

            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{ textAlign: 'center' }}>
          <SignInPage {...props} provider={AzureProvider} align="center" />
        </div>
      </div>
    ),
  },

  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  themes: [
    {
      id: 'sasTheme',
      title: 'SAS',
      variant: 'light',
      Provider: ({ children }) => (
        <ThemeProvider theme={sasTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
    {
      id: 'light',
      title: 'Light',
      variant: 'light',
      Provider: ({ children }) => (
        <ThemeProvider theme={lightTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
    {
      id: 'dark',
      title: 'Dark',
      variant: 'dark',
      Provider: ({ children }) => (
        <ThemeProvider theme={darkTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
  ],
});

const templatesGroups: TemplateGroupFilter[] = [
  {
    title: 'Web Solutions',
    filter: entity =>
      (entity.spec.type === 'website' ||
        entity?.metadata?.tags?.includes('web')) ??
      false,
  },
  {
    title: 'Backend Solutions',
    filter: entity =>
      (entity.spec.type === 'service' ||
        entity?.metadata?.tags?.includes('backend')) ??
      false,
  },
  {
    title: 'Infra Solutions',
    filter: entity => entity?.metadata?.tags?.includes('infra') ?? false,
  },
  {
    title: 'Other Solutions',
    filter: entity => {
      const tags = entity?.metadata?.tags;
      const hasknownTags =
        (tags?.includes('web') ||
          tags?.includes('backend') ||
          tags?.includes('infra')) ??
        false;

      return (
        !['website', 'service'].includes(entity?.spec?.type) && !hasknownTags
      );
    },
  },
];

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      {<HomePage />}
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <FeatureFlagged with="experimental-templates">
      <Route
        path="/create"
        element={<ScaffolderPage groups={templatesGroups} />}
      />
    </FeatureFlagged>
    <FeatureFlagged without="experimental-templates">
      <Route
        path="/create"
        element={
          <ScaffolderPage
            templateFilter={template =>
              !template.metadata.tags?.includes('experimental') ?? true
            }
            groups={templatesGroups}
          />
        }
      />
    </FeatureFlagged>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={
        <TechRadarPage
          width={1500}
          id="sas-tech-radar"
          height={800}
          title="SAS Tech Radar"
          pageTitle="SAS Tech Radar"
          subtitle="Recommended technologies, platform and tools to be used in SAS"
        />
      }
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/opsgenie" element={<OpsgeniePage />} />
    <Route path="/announcements" element={<AnnouncementsPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <VisitListener />
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
